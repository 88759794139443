import { graphql, Link } from "gatsby"
import React from "react"
// import { makeBlogPath } from "../utils"
// import dateformat from "dateformat"
import moment from "moment";
import SEO from '../components/seopagetagcat'
import Layout from '../components/Layout'
//import { rhythm } from '../utils/typography'
import { Card, Row, Col, Button } from 'react-bootstrap'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { SlugLink } from '../components/utils'
import NewsLists from '../templates/newlists';
const baseuri = "https://dmf5.xyz"
class PostCategory extends React.Component {
  renderTags(tags) {
    tags.map((item) => {
      //console.log(item)
      return <Button variant="outline-secondary">{item}</Button>
    })
  }
  render() {
    const { data, location, pageContext } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteDescription = data.site.siteMetadata.description
    const domainName = data.site.siteMetadata.domainName
    const posts = data.privateGraphql.postbycategory
    const cate = data.privateGraphql.getcategory
    //const { currentPage, numPages, limit, skip } = this.props.pageContext
    const { currentPage, numPagesc } = this.props.pageContext
    let curr = 1;
    if (currentPage === undefined) { curr = 1 }
    else { curr = currentPage }
    const isFirst = curr === 1
    const isLast = curr === numPagesc
    const prevPage = curr - 1 === 1 ? '/' : (curr - 1).toString()
    const nextPage = (curr + 1).toString()

    const MetaTitle = (cate.metatitle !== "" && cate.metatitle !== null) ? cate.metatitle : cate.name;
    const MetaDescription = (cate.metadesc !== "" && cate.metadesc !== null) ? cate.metadesc : "Description  " + cate.name;

    const tagHeader = `${cate.name}`
    if (location != null) {
      if (location.href != null && location.href.endsWith('/')) {
        window.location.href = window.location.href.substring(0, window.location.href.length - 1);
      }
    }
    return (
      <Layout location={this.props.location} title={MetaTitle}>
        {posts.length > 0 ?
          <SEO
            title={`${MetaTitle}`}
            description={`${cate.count} - ${MetaDescription}`}
            canonical={`https://${domainName}/${cate.slugname}`}
            datePublished={posts[0].publishDate}
            dateModified={posts[0].modifiedDate}
            image={posts[0].image}
          />
          :
          <SEO
            title={`${MetaTitle}`}
            description={`${cate.count} - ${MetaDescription}`}
            canonical={`https://${domainName}/${cate.slugname}`}
          />
        }

        <div className="w-layout-container">
          <header className="w-page-header">
            <h1 className="w-page-header__headline">{tagHeader}</h1>
            <p className="w-page-header__copy">
              {cate.descriptionwithhtml ?
                <section dangerouslySetInnerHTML={{ __html: cate.descriptionwithhtml }} />
                :
                cate.metadesc
              }
            </p>
          </header>
        </div>
        <ul className="row feature-list feature-list-sm">
          {posts.map(node => {
            const title = node.title || node.slugtitle
            return (
              <NewsLists key={node.slugtitle} node={node} />
            )
          })}
          <li className="col-12 text-center w-pagination">
            <ul
            >
              {!isFirst && (
                <li className="w-pagination__link">
                  <Link to={curr <= 2 ? `/${cate.slugname}` : `/${cate.slugname}/page/${prevPage}`} rel="prev">
                    ←
                </Link>
                </li>
              )}
              {Array.from({ length: numPagesc }, (_, i) => {
                if (i < curr + 3 && i > curr - 3) {
                  return (
                    <li
                      key={`pagination-number${i + 1}`}
                      className="w-pagination__link"
                    >
                      <Link
                        to={`/${cate.slugname}${i === 0 ? '' : '/page/' + (i + 1)}`}
                        style={{
                          textDecoration: 'none',
                          color: i + 1 === curr ? '#000' : '',
                          background: i + 1 === curr ? '#fff' : '',
                        }}
                      >
                        {i + 1}
                      </Link>
                    </li>
                  )
                }
              })}
              {!isLast && (
                <li className="w-pagination__link">
                  <Link to={`/${cate.slugname}/page/${nextPage}`} rel="next">
                    →
                </Link>
                </li>
              )}
            </ul>
          </li>
        </ul>
      </Layout>
    )
  }
}
export default PostCategory
export const query = graphql`
query ($category: String,$limit: Int = 21, $skip: Int = 0, $siteId: Int = 1051) {
  site {
    siteMetadata {
      title
      domainName
      description
    }
  }
  privateGraphql {
    getcategory(slugtitle: $category, siteId: $siteId) {
      id
      image
      metadesc
      metatitle
      name
      slugname
      descriptionwithhtml
      count
    }
    postbycategory(
      slugtitle: $category
      limit: $limit 
      skip: $skip
      siteId: $siteId
      ) {
        id
        categoryName
        image
        profileName
        profileSlugName
        publishDate
        modifiedDate
        short
        slugCategoryName
        tags
        slugtags
        slugtitle
        title
        profileImage
    }
  }
}
`
